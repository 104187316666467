import qz from 'qz-tray';

import domtoimage from 'dom-to-image';

var rs = require('jsrsasign');

import * as logoImage from '@/assets/img/logo.png';

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const convertHtmlToImage = async (html) => {
  const element = document.createElement('div');

  element.setAttribute('id', 'print-content-temporary');

  document.getElementsByTagName('body')[0].append(element);

  document.getElementById('print-content-temporary').innerHTML = html;
  document
    .getElementById('print-content-temporary')
    .getElementsByTagName('div')[0]
    .classList.add('big');

  return await domtoimage
    .toPng(document.getElementById('print-content-temporary'), { width: 300 })
    .then((data) => {
      // console.log(document.getElementById('print-content-temporary'))
      document.getElementById('print-content-temporary').remove();

      return data;
    });
};

var certKey =
  '-----BEGIN CERTIFICATE-----\n' +
  'MIIEGzCCAwOgAwIBAgIUB1jAfup8IFg7YlxIBYJkRM3AfN4wDQYJKoZIhvcNAQEL\n' +
  'BQAwgZsxCzAJBgNVBAYTAkJSMRowGAYDVQQIDBFSaW8gR3JhbmRlIGRvIFN1bDEU\n' +
  'MBIGA1UEBwwLRmFycm91cGlsaGExDjAMBgNVBAoMBUdvaHViMQ4wDAYDVQQLDAVH\n' +
  'b2h1YjESMBAGA1UEAwwJbG9jYWxob3N0MSYwJAYJKoZIhvcNAQkBFhdsdWNhcy5w\n' +
  'ZWxsZUBob3RtYWlsLmNvbTAgFw0yMTAyMjIyMDE5NDNaGA8yMDUyMDgxNzIwMTk0\n' +
  'M1owgZsxCzAJBgNVBAYTAkJSMRowGAYDVQQIDBFSaW8gR3JhbmRlIGRvIFN1bDEU\n' +
  'MBIGA1UEBwwLRmFycm91cGlsaGExDjAMBgNVBAoMBUdvaHViMQ4wDAYDVQQLDAVH\n' +
  'b2h1YjESMBAGA1UEAwwJbG9jYWxob3N0MSYwJAYJKoZIhvcNAQkBFhdsdWNhcy5w\n' +
  'ZWxsZUBob3RtYWlsLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB\n' +
  'AMbtnbw0mChyIpaxFeUENhE8d7KXvZUSGvtO65GvphVzMs/PE7gbuCDe6gEESQXT\n' +
  'sAYLJ6VCEgRfk9wXHzEsr2rGIBhpvGdnstgPt24F8xBh/eZpOgM9gIdicw8lZ3bW\n' +
  'J5cQw8kZhvuhzdiNw/vIJvYlTaKJgmTa2N2zxJ3FQKn75A85nbYiERieFBTUds7s\n' +
  'JfjrKxSEyKZ3EhZiwuVNqRl5U6Ce5NGUyudwmvI4CV3hB+f88it43VD9SsZ3viXp\n' +
  'ltTbucCmAtAwyFLvd4CgzU62hcXg0LZmxqQsf9sfEHvQt4t+5yvPbwOAndw/1aSC\n' +
  'zSl/l/yVpcnQ59O8Bn6jEjUCAwEAAaNTMFEwHQYDVR0OBBYEFGLZxjias7wdjAfl\n' +
  'A9XdhTtuG45SMB8GA1UdIwQYMBaAFGLZxjias7wdjAflA9XdhTtuG45SMA8GA1Ud\n' +
  'EwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBAKgeFvgzEAq1qs9wAGl4bwqa\n' +
  'T9dmidfduhbpvkfmZwT+oV4MhIzI+duO4ZT/RBs8xVowRge5A1vYzlbmS0tUymGb\n' +
  'IxOHjIMe+b+961Y7sAS9BxpCZewHvO7S3ZCsAoWjot+XfbPN5ydJ+fOnU+cLjyZp\n' +
  'AuAHrczqeUty39MGSVq7qbzncaSLVAqR2XVQBcBszjSZq2ahu583TBxnSzELNCVu\n' +
  'ufrLo5K/j9+h3c62LnyemHcv7y0tHwIE0CxUdEVNlbQE1hrj1FDux5m0s6MNs41Z\n' +
  'UvkuFE0cLG2Q8J1kFLoomFkM/r1uPcSGY3GYpJdtcTHtixiVlBaBxC9q/Q0PPoA=\n' +
  '-----END CERTIFICATE-----';
/// Authentication setup ///
qz.security.setCertificatePromise(function (resolve, reject) {
  resolve(certKey);
});

var privateKey =
  '-----BEGIN PRIVATE KEY-----\n' +
  'MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDG7Z28NJgociKW\n' +
  'sRXlBDYRPHeyl72VEhr7TuuRr6YVczLPzxO4G7gg3uoBBEkF07AGCyelQhIEX5Pc\n' +
  'Fx8xLK9qxiAYabxnZ7LYD7duBfMQYf3maToDPYCHYnMPJWd21ieXEMPJGYb7oc3Y\n' +
  'jcP7yCb2JU2iiYJk2tjds8SdxUCp++QPOZ22IhEYnhQU1HbO7CX46ysUhMimdxIW\n' +
  'YsLlTakZeVOgnuTRlMrncJryOAld4Qfn/PIreN1Q/UrGd74l6ZbU27nApgLQMMhS\n' +
  '73eAoM1OtoXF4NC2ZsakLH/bHxB70LeLfucrz28DgJ3cP9Wkgs0pf5f8laXJ0OfT\n' +
  'vAZ+oxI1AgMBAAECggEAJSJyUZHX3aUAw/Smy6uvlCQfjB07bLqGgudGnlmVvWdV\n' +
  '0ukrGzFBfBFgyXPNVeMgduBaNEVTK7HT3kAKuJlI3bvCfgwu+XaoMiEs8sL1jtdc\n' +
  'GEkIZVZzydIc/HTVDaAIn/CnO8EiK5MW5BgCv9urOkPYbEETMFSSK0DFsVYDLjfd\n' +
  'BWNop7ffBoY50/9SgmddIe0Z0FApJ7J5QIJBGds1Hy195/QR83xecxKyXIQNPMqx\n' +
  'ItpaxtcCgiZ9RMcbTzqT7xQTPx9XjJIJ4sg6Yv8y0nIydnB9mo0uhD+LIQc6/AER\n' +
  'lKJiA/a1Dl11FmuLjr/PUxDAGzHgnAsHfi0JZGmEOQKBgQDu1C9G/lT/61I2q3cw\n' +
  'X/sk4KQjBqPjCAZ4X5s4pAKUIsVl9gkKsfPhsa9vSH2f0cQFUJFWZ07GQkTFpyuX\n' +
  'pwGjqZJW2wpOZNKCav2e02U6Gx4VQVxaM92VTiFHNEAikhwpPN+mlgcSejVcuI9F\n' +
  '8sLAw2GoWvQrNvJ2iL4EaAkxXwKBgQDVOwgJOp1s8m2hhMtU4KbwPnC4V1OpkPjD\n' +
  'Ktdo2yErQxE7hSEstDu3tmEX7Q6e7laOYcdPkr5L7q8oWtfNzqz1RNC5azmNFOng\n' +
  'htlW31YnZuIJIiYMD+QHKaOcvxhDrka/p7kExfq6bTOUFv7n83gFpIO757sYb435\n' +
  'PYFYJQVA6wKBgAwMNB5Wsh7+1fTgDXEpRlgD5QPHT/kh7IGiAQiIbYDfiCmukaIy\n' +
  'e+UEu57Xf07SnBrQh6ReLcEekq2N00shJ5oD8dfj0M9Hkr71ernKXC7kM05LL8d1\n' +
  'GlZvzIn0/s8/o6AI7BdRNiYeeBanUMLsjhp/QKvneq27ZdvI5hz+yoftAoGAWJu5\n' +
  'USx8cuOjTzWB0XmxpbAs403WxGYsGd5ZuYcmF3Y9WQCPsqYLEuLFIk4ufzkH2w8i\n' +
  '5hxS2/cJ7rkhaLL0CNBZ9FNsjQYd0mH3OzR/9su4Lpd0PBr2ZUuWY/K9QJLRb59h\n' +
  'MWycLpBmmKmdsAHq5F1jKNi5VQQ2eVZnw5tOvZUCgYEAkr+NLti2CjBAmLKdnkY9\n' +
  'AFbToFRkeiz/0KrMIqWtfKLEPiJPvjwH2GeIl3jd2PJJHlyusJpWEIYRUdOMGPGK\n' +
  'yqVox2wUiId9w5ZDWlBZttMEGWbD9ehgwd9+0I6UZRFWbb4aDVC8PGLMWD0J0t7m\n' +
  'LSTwfERS1V54TbLrzA7jIbg=\n' +
  '-----END PRIVATE KEY-----';

qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      var pk = rs.KEYUTIL.getKey(privateKey);
      var sig = new rs.KJUR.crypto.Signature({ alg: 'SHA1withRSA' });
      sig.init(pk);
      sig.updateString(toSign);
      var hex = sig.sign();
      // console.log('DEBUG: \n\n' + stob64(hextorstr(hex)));
      resolve(rs.stob64(rs.hextorstr(hex)));
    } catch (err) {
      console.error(err);
      reject(err);
    }
  };
});

async function verifyIfConectorAlreadyIsInstalled() {
  if (qz.websocket.isActive()) return true;

  return await qz.websocket
    .connect()
    .then(async () => {
      await qz.websocket.disconnect();
      return true;
    })
    .catch(() => {
      return false;
    });
}

async function getAvailablePrinters() {
  if (!qz.websocket.isActive()) {
    return await qz.websocket
      .connect()
      .then(() => {
        return qz.printers.details();
      })
      .catch((err) => {
        qz.websocket.disconnect();
        return err;
      });
  } else {
    return qz.printers.details();
  }
}

function openPdf(base64EncodedPDF, name = 'NF-DANFE') {
  // console.log(base64EncodedPDF);
  let a = document.createElement('a');
  a.href = 'data:application/octet-stream;base64,' + base64EncodedPDF;
  a.download = ('' + name).replace('.pdf', '') + '.pdf';
  a.click();
}

function openXml(xmlContent, name = 'NF-XML') {
  // Convert XML content string to Blob
  const blob = new Blob([xmlContent], { type: 'application/xml' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  
  link.href = url;
  link.download = `${name.replace('.xml', '')}.xml`; // Ensure the filename ends with .xml
  link.style.display = 'none'; // Hide the link
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url); // Clean up
}

async function print(base64, type, printerName = '') {
  // console.log(base64, type, printerName)
  if (qz.websocket.isActive()) await qz.websocket.disconnect();
  return await qz.websocket
    .connect()
    .then(() => {
      qz.printers.details().then((printers) => {});
      if (printerName.length > 0) return qz.printers.find(printerName);

      return qz.printers.getDefault();
    })
    .then(async (printers) => {
      // console.log(printers);
      const printerName =
        Array.isArray(printers) && printers.length > 0 ? printers[0] : printers;

      if (!printerName.length) {
        alert('Nenhuma impressora detectada');
        return null;
      }

      let config = qz.configs.create(printerName, {
        margins: { left: 2.5 /* mm */ },
        /* size: { width: 80, height: 297 }, */ units: 'mm',
        altPrinting: false,
      });

      if (type == 'NF-e') {
        return qz.print(config, [
          {
            type: 'pixel',
            flavor: 'base64',
            format: 'pdf',
            data: base64,
          },
        ]);
      } else if (type == 'NFC-e') {
        return qz.print(config, [
          {
            type: 'pixel',
            options: { language: 'ECSPOS', dotDensity: 'double' },
            flavor: 'base64',
            format: 'pdf',
            data: base64,
          },
        ]);
        // return qz.print(config, [
        //   {
        //     type: 'raw',
        //     options: { language: 'ECSPOS', dotDensity: 'double' },
        //     format: 'base64',
        //     data: base64
        //   }
        // ]);
      } else {
        const converted = await convertHtmlToImage(base64);

        config = qz.configs.create(printerName, {
          scaleContent: 'false',
          rasterize: 'false',
          density: '900',
          units: 'in',
          margins: { left: 2.5, right: 2.5 },
        });

        // console.log(converted);
        // let w = window.open('about:blank');
        // let image = new Image();
        // image.src = converted;
        // setTimeout(function() {
        //   w.document.write(image.outerHTML);
        // }, 0);

        // return true;

        // console.log(converted);
        return qz.print(config, [
          {
            type: 'raw',
            format: 'image',
            flavor: 'base64',
            data: converted,
            options: {
              language: 'ESCPOS',
              dotDensity: 'single',
              // y: 5,
              // x: '0',
              // xmlTag: 'v7:Image',
              // y: '0'
            },
          },
          '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',
          '\x1B' + '\x69', // cut paper (old syntax)
          '\x10' + '\x14' + '\x01' + '\x00' + '\x05',
        ]);
      }
    })
    .then((result) => {
      return qz.websocket.disconnect();
    })
    .then(() => {
      console.log('printed');
    })
    .catch(async (err) => {
      console.log(err);
      await qz.websocket.disconnect();
      if (('' + err).includes('Specified printer could not be found'))
        throw 'Não foi possível localização a impressora ' + printerName;
      else throw err;
    });
}

export default {
  print,
  verifyIfConectorAlreadyIsInstalled,
  getAvailablePrinters,
  openXml,
  openPdf,
};
